/* eslint-disable react-hooks/rules-of-hooks */
import React from 'react';
import Router from 'next/router';
import { useSelector, useDispatch } from 'react-redux';
import {
  getCurrentUser,
  getHasVerificationBeenAttempted,
  hasSessionExpired,
  verifyTokenThunk,
} from 'stores/currentUser';
import { useToast } from '@chakra-ui/react';
import { handleToastMessage } from 'utils/toast';
import { TOAST_STATUS } from 'utils/constants';

export function ProtectedRoute(Component) {
  return props => {
    const toast = useToast();
    const currentUser = useSelector(getCurrentUser);
    const hasVerificationBeenAttempted = useSelector(
      getHasVerificationBeenAttempted,
    );
    const sessionExpired = useSelector(hasSessionExpired);
    const dispatch = useDispatch();
    if (hasVerificationBeenAttempted && !currentUser) {
      Router.push('/sign_in');
      if (sessionExpired) {
        handleToastMessage(
          toast,
          'Your session has expired. Please sign in again',
          TOAST_STATUS.ERROR,
        );
      }
      return null;
    }

    if (!hasVerificationBeenAttempted) {
      dispatch(verifyTokenThunk());
    }

    return hasVerificationBeenAttempted && <Component {...props} />;
  };
}

/* eslint-enable react-hooks/rules-of-hooks */
