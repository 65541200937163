import React from 'react';
import PropTypes from 'prop-types';

const TankIcon = ({ inventoryRatio, color }) => {
  const HEIGHT = 33.4769;
  const fillPercentage =
    inventoryRatio >= 0 ? (inventoryRatio * HEIGHT) / 100 : 16.73845;
  return (
    <svg fill='none' viewBox='0 0 22 40'>
      <mask
        id='path-1-outside-1_1175_4408'
        maskUnits='userSpaceOnUse'
        x='0'
        y='0'
        width='18'
        height='34'
        fill='black'
      >
        <rect fill='white' width='18' height='34' />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M12.0033 1C12.5556 1 13.0033 1.44772 13.0033 2V2.96923H15C16.1046 2.96923 17 3.86466 17 4.96923V29.0308C17 30.1353 16.1046 31.0308 15 31.0308H13.0033V32C13.0033 32.5523 12.5556 33 12.0033 33H5.69331C5.14103 33 4.69331 32.5523 4.69331 32V31.0308H3C1.89543 31.0308 1 30.1353 1 29.0308V4.96923C1 3.86466 1.89543 2.96923 3 2.96923H4.69331V2C4.69331 1.44772 5.14103 1 5.69331 1H12.0033Z'
        />
      </mask>
      <path
        d='M13.0033 2.96923V3.96923H12.0033V2.96923H13.0033ZM13.0033 31.0308H12.0033V30.0308H13.0033V31.0308ZM4.69331 31.0308V30.0308H5.69331V31.0308H4.69331ZM4.69331 2.96923H5.69331V3.96923H4.69331V2.96923ZM12.0033 2V0C13.1078 0 14.0033 0.89543 14.0033 2H12.0033ZM12.0033 2.96923V2H14.0033V2.96923H12.0033ZM15 3.96923H13.0033V1.96923H15V3.96923ZM16 4.96923C16 4.41695 15.5523 3.96923 15 3.96923V1.96923C16.6569 1.96923 18 3.31238 18 4.96923H16ZM16 29.0308V4.96923H18V29.0308H16ZM15 30.0308C15.5523 30.0308 16 29.5831 16 29.0308H18C18 30.6876 16.6569 32.0308 15 32.0308V30.0308ZM13.0033 30.0308H15V32.0308H13.0033V30.0308ZM12.0033 32V31.0308H14.0033V32H12.0033ZM12.0033 32H12.0033H14.0033C14.0033 33.1046 13.1078 34 12.0033 34V32ZM5.69331 32H12.0033V34H5.69331V32ZM5.69331 32V34C4.58874 34 3.69331 33.1046 3.69331 32H5.69331ZM5.69331 31.0308V32H3.69331V31.0308H5.69331ZM3 30.0308H4.69331V32.0308H3V30.0308ZM2 29.0308C2 29.5831 2.44772 30.0308 3 30.0308V32.0308C1.34315 32.0308 0 30.6876 0 29.0308H2ZM2 4.96923V29.0308H0V4.96923H2ZM3 3.96923C2.44771 3.96923 2 4.41695 2 4.96923H0C0 3.31238 1.34315 1.96923 3 1.96923V3.96923ZM4.69331 3.96923H3V1.96923H4.69331V3.96923ZM5.69331 2V2.96923H3.69331V2H5.69331ZM5.69331 2H5.69331H3.69331C3.69331 0.895431 4.58874 0 5.69331 0V2ZM12.0033 2H5.69331V0H12.0033V2Z'
        fill={color || '#414D58'}
        mask='url(#path-1-outside-1_1175_4408)'
      />
      <mask
        id='mask0_1175_4408'
        style={{ maskType: 'alpha' }}
        maskUnits='userSpaceOnUse'
        x='0'
        y='0'
        width='18'
        height='34'
      >
        <mask
          id='path-3-outside-2_1175_4408'
          maskUnits='userSpaceOnUse'
          x='0'
          y='0'
          width='18'
          height='34'
          fill='black'
        >
          <rect fill='white' width='18' height='34' />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M12.0033 1C12.5556 1 13.0033 1.44772 13.0033 2V2.96923H15C16.1046 2.96923 17 3.86466 17 4.96923V29.0308C17 30.1353 16.1046 31.0308 15 31.0308H13.0033V32C13.0033 32.5523 12.5556 33 12.0033 33H6.15498C5.60269 33 5.15498 32.5523 5.15498 32V31.0308H3C1.89543 31.0308 1 30.1353 1 29.0308V4.96923C1 3.86466 1.89543 2.96923 3 2.96923H5.15498V2C5.15498 1.44772 5.60269 1 6.15498 1H12.0033Z'
          />
        </mask>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M12.0033 1C12.5556 1 13.0033 1.44772 13.0033 2V2.96923H15C16.1046 2.96923 17 3.86466 17 4.96923V29.0308C17 30.1353 16.1046 31.0308 15 31.0308H13.0033V32C13.0033 32.5523 12.5556 33 12.0033 33H6.15498C5.60269 33 5.15498 32.5523 5.15498 32V31.0308H3C1.89543 31.0308 1 30.1353 1 29.0308V4.96923C1 3.86466 1.89543 2.96923 3 2.96923H5.15498V2C5.15498 1.44772 5.60269 1 6.15498 1H12.0033Z'
          fill='white'
        />
        <path
          d='M13.0033 2.96923V3.96923H12.0033V2.96923H13.0033ZM13.0033 31.0308H12.0033V30.0308H13.0033V31.0308ZM5.15498 31.0308V30.0308H6.15498V31.0308H5.15498ZM5.15498 2.96923H6.15498V3.96923H5.15498V2.96923ZM12.0033 2V0C13.1078 0 14.0033 0.89543 14.0033 2H12.0033ZM12.0033 2.96923V2H14.0033V2.96923H12.0033ZM15 3.96923H13.0033V1.96923H15V3.96923ZM16 4.96923C16 4.41695 15.5523 3.96923 15 3.96923V1.96923C16.6569 1.96923 18 3.31238 18 4.96923H16ZM16 29.0308V4.96923H18V29.0308H16ZM15 30.0308C15.5523 30.0308 16 29.5831 16 29.0308H18C18 30.6876 16.6569 32.0308 15 32.0308V30.0308ZM13.0033 30.0308H15V32.0308H13.0033V30.0308ZM12.0033 32V31.0308H14.0033V32H12.0033ZM12.0033 32H12.0033H14.0033C14.0033 33.1046 13.1078 34 12.0033 34V32ZM6.15498 32H12.0033V34H6.15498V32ZM6.15498 32V34C5.05041 34 4.15498 33.1046 4.15498 32H6.15498ZM6.15498 31.0308V32H4.15498V31.0308H6.15498ZM3 30.0308H5.15498V32.0308H3V30.0308ZM2 29.0308C2 29.5831 2.44772 30.0308 3 30.0308V32.0308C1.34315 32.0308 0 30.6876 0 29.0308H2ZM2 4.96923V29.0308H0V4.96923H2ZM3 3.96923C2.44771 3.96923 2 4.41695 2 4.96923H0C0 3.31238 1.34315 1.96923 3 1.96923V3.96923ZM5.15498 3.96923H3V1.96923H5.15498V3.96923ZM6.15498 2V2.96923H4.15498V2H6.15498ZM6.15498 2H6.15498H4.15498C4.15498 0.895431 5.05041 0 6.15498 0V2ZM12.0033 2H6.15498V0H12.0033V2Z'
          fill='#414D58'
          mask='url(#path-3-outside-2_1175_4408)'
        />
      </mask>
      <g mask='url(#mask0_1175_4408)'>
        <rect
          x='-0.5'
          y='0.5'
          width='15.1582'
          height={fillPercentage}
          transform={`matrix(-1 0 0 1 16 ${HEIGHT - fillPercentage})`}
          fill={color || '#414D58'}
          stroke={color || '#414D58'}
        />
      </g>
    </svg>
  );
};

TankIcon.propTypes = {
  color: PropTypes.string,
  inventoryRatio: PropTypes.number,
};

export default TankIcon;
