import React from 'react';
import { createIcon } from '@chakra-ui/react';

const DollarSign = createIcon({
  displayName: 'Dollar sign',
  viewBox: '-2 10 39 20',
  fill: 'currentColor',
  path: (
    <>
      <ellipse cx='17' cy='19.9047' rx='16.5' ry='16.2857' fill='white' />
      <path
        d='M21.8587 23.224C21.8587 24.888 21.3547 26.152 20.3467 27.016C19.3387 27.864 18.0187 28.288 16.3867 28.288H16.3627L16.0027 31.12C14.8187 31.072 14.2267 31.016 14.2267 30.952L14.5627 28.168C13.3787 28.024 12.3867 27.856 11.5867 27.664L11.1067 27.568L11.4187 25.072C12.6827 25.248 13.8507 25.368 14.9227 25.432L15.4507 21.256C13.8027 20.76 12.6347 20.16 11.9467 19.456C11.2587 18.752 10.9147 17.792 10.9147 16.576C10.9147 14.992 11.3947 13.816 12.3547 13.048C13.3147 12.264 14.6347 11.872 16.3147 11.872H16.6267L17.0587 8.56H18.8347L18.4027 12.016C19.3947 12.112 20.3067 12.24 21.1387 12.4L21.5467 12.496L21.2827 15.04C20.1147 14.912 19.0347 14.816 18.0427 14.752L17.5627 18.616C19.2267 19.144 20.3547 19.728 20.9467 20.368C21.5547 20.992 21.8587 21.944 21.8587 23.224ZM14.1067 16.36C14.1067 16.744 14.2267 17.056 14.4667 17.296C14.7067 17.536 15.1707 17.784 15.8587 18.04L16.2667 14.704C14.8267 14.768 14.1067 15.32 14.1067 16.36ZM18.6667 23.44C18.6667 23.056 18.5547 22.744 18.3307 22.504C18.1227 22.248 17.7387 22.008 17.1787 21.784L16.7227 25.456C18.0187 25.312 18.6667 24.64 18.6667 23.44Z'
        fill='#004D96'
      />
    </>
  ),
});

export default DollarSign;
