export const handleToastMessage = (toast, message, status) => {
  toast({
    title: '',
    description: message,
    status,
    duration: 5000,
    position: 'top',
    isClosable: true,
  });
};
