import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import Colors from 'utils/colors';
import Meta from 'layouts/Meta';

import capitalize from 'lodash.capitalize';
import { Box, Flex, Icon, Image, Text } from '@chakra-ui/react';
import { IoReorderThreeSharp } from 'react-icons/io5';

import {
  getCurrentUser,
  getCurrentUserCompany,
  getCurrentUserRole,
  signOutThunk,
} from 'stores/currentUser';
import Dropdown from 'components/Dropdown';
import i18n from 'lib/i18n';
import { getCurrentCompanyId } from 'lib/auth';
import { getCurrentCompany } from 'stores/company';

import { useRouter } from 'next/router';
import { RESOURCE_STATUS } from 'utils/constants';
import SideBar from './SideBar';

const { withTranslation } = i18n;
const useStyles = makeStyles(theme => ({
  appBar: {
    backgroundColor: Colors.white,
    zIndex: theme.zIndex.drawer + 1,
  },
  toolbar: theme.mixins.toolbar,
}));

const MainLayout = ({ children, t }) => {
  const classes = useStyles();
  const currentUserRole = useSelector(getCurrentUserRole);
  const currentUser = useSelector(getCurrentUser);
  const currentCompanyId = getCurrentCompanyId();
  const dispatch = useDispatch();
  const router = useRouter();
  const { value: currentCompany, resourceStatus } = useSelector(
    getCurrentCompany,
  );
  const currentUserCompany = useSelector(getCurrentUserCompany);
  const signOut = () => dispatch(signOutThunk());
  const [isCollapsed, setIsCollapsed] = useState(true);

  useEffect(() => {
    setIsCollapsed(true);
  }, [currentCompanyId]);

  const renderSidebar = () => {
    return (
      <Box
        bg='alpha.500'
        overflow='auto'
        position='fixed'
        height='100%'
        zIndex='2'
      >
        <SideBar
          visibility={currentUser.visibility}
          isCollapsed={isCollapsed}
          currentUserRole={currentUserRole}
          setIsCollapsed={setIsCollapsed}
          currentUserCompany={currentUserCompany}
        />
      </Box>
    );
  };

  const renderAppBar = () => {
    const { firstName, lastName } = currentUser;

    const handleClick = () => setIsCollapsed(previousState => !previousState);

    return (
      <Flex ml='6' alignItems='center' h='70px'>
        <Icon
          as={IoReorderThreeSharp}
          display={{ xs: 'flex', lg: 'none' }}
          color='alpha.500'
          boxSize='8'
          onClick={handleClick}
        />
        <Flex p='3' ml={{ xs: '4', lg: '0' }} w='100%' h='100%'>
          {resourceStatus === RESOURCE_STATUS.READY && (
            <Image
              src={currentCompany?.logoUrl || '/images/xact-logo.png'}
              alt='company logo'
            />
          )}
        </Flex>
        <Flex
          ml='auto'
          color='alpha.500'
          alignItems='center'
          mr='6'
          cursor='pointer'
          justify='flex-end'
          w='100%'
        >
          <Dropdown text={`${firstName} ${lastName}`}>
            <Text
              onClick={() => router.push('/profile')}
              color='alpha.500'
              py='4'
              px='2'
              _hover={{ color: 'alpha.800' }}
            >
              {capitalize(t('users.profile'))}
            </Text>
            <Text
              onClick={signOut}
              color='epsilon.500'
              py='4'
              px='2'
              _hover={{ color: 'alpha.600' }}
            >
              {capitalize(t('users.log_out'))}
            </Text>
          </Dropdown>
        </Flex>
      </Flex>
    );
  };

  return (
    <>
      <Meta />
      <header>
        <nav />
      </header>
      <Flex>
        <AppBar position='fixed' className={classes.appBar}>
          {renderAppBar()}
        </AppBar>
        {currentUser ? renderSidebar() : <div />}
        <Box
          position='fixed'
          hidden={isCollapsed}
          background='rgba(0, 0, 0, 0.5)'
          top='0'
          bottom='0'
          left='0'
          right='0'
          zIndex='1'
          onClick={() => {
            setIsCollapsed(true);
          }}
        />
        <Box
          bg='gray.100'
          p={{ xs: '10px', md: '16px' }}
          width='100%'
          minHeight='100vh'
          ml={{ md: '72px', lg: '260px' }}
        >
          <div className={classes.toolbar} />
          <Box height='93%'>{children}</Box>
        </Box>
      </Flex>
    </>
  );
};

MainLayout.propTypes = {
  children: PropTypes.node.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(MainLayout);
