import { Box, Flex, Icon, Text, useOutsideClick } from '@chakra-ui/react';
import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { IoChevronDown, IoChevronUp } from 'react-icons/io5';

const Dropdown = ({ children, text, listHeight }) => {
  const ref = useRef();
  const [isListVisible, setIsListVisible] = useState(false);
  useOutsideClick({
    enabled: isListVisible,
    handler: () => setIsListVisible(false),
    ref,
  });

  const handleClick = () => {
    setIsListVisible(previousState => !previousState);
  };

  return (
    <Box position='relative' ref={ref}>
      <Flex
        cursor='pointer'
        borderColor='gray.500'
        p='2'
        alignItems='center'
        onClick={handleClick}
        justifyContent='space-between'
      >
        <Text variant='headingLg' mr='3' color='alpha.500'>
          {text}
        </Text>
        <Icon as={isListVisible ? IoChevronUp : IoChevronDown} boxSize='5' />
      </Flex>
      <Box
        border='1px'
        borderColor='gray.200'
        cursor='pointer'
        shadow='lg'
        width='100%'
        p='1'
        bg='white'
        maxHeight={listHeight}
        overflow='auto'
        position='absolute'
        borderRadius='10px'
        hidden={!isListVisible}
        onClick={handleClick}
      >
        {children}
      </Box>
    </Box>
  );
};

Dropdown.propTypes = {
  text: PropTypes.string,
  children: PropTypes.node.isRequired,
  listHeight: PropTypes.string,
};

export default Dropdown;
