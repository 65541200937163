import React, { useEffect, useState } from 'react';
import Head from 'next/head';
import PropTypes from 'prop-types';
import { DEFAULT_BRANDING, KBC_BRANDING, KBC_URL } from 'utils/constants';
import { getUrl, updateUrl } from 'stores/currentUser';
import { useDispatch, useSelector } from 'react-redux';
import Script from 'next/script';

const Meta = props => {
  const {
    charSet,
    viewport,
    description,
    ogType,
    ogTitle,
    ogDescription,
    ogSiteName,
    ogUrl,
    ogImage,
    twitterCard,
    twitterDescription,
    twitterSite,
    twitterCreator,
    twitterImage,
    children,
  } = props;

  const dispatch = useDispatch();
  const url = useSelector(getUrl);

  const [title, setTitle] = useState('');
  const [favicon, setFavicon] = useState('');
  const [helpWidgetUrl, setHelpWidgetUrl] = useState('');

  useEffect(() => {
    if (!url) {
      dispatch(updateUrl(window.location.origin));
    } else {
      const branding = url === KBC_URL ? KBC_BRANDING : DEFAULT_BRANDING;
      setTitle(branding.title);
      setFavicon(branding.favicon);
      setHelpWidgetUrl(branding.helpWidgetUrl);
    }
  }, [dispatch, url]);

  return (
    <>
      <Head>
        <title>{title}</title>
        <link rel='shortcut icon' href={`/static/${favicon}.ico`} />
        <meta charSet={charSet} />
        <meta name='viewport' content={viewport} />
        {description && <meta name='description' content={description} />}
        {ogType && <meta property='og:type' content={ogType} />}
        {ogTitle && (
          <meta name='og:title' property='og:title' content={ogTitle} />
        )}
        {ogDescription && (
          <meta
            name='og:description'
            property='og:description'
            content={ogDescription}
          />
        )}
        {ogSiteName && <meta property='og:site_name' content={ogSiteName} />}
        {ogUrl && <meta property='og:url' content={ogUrl} />}
        {ogImage && <meta property='og:image' content={ogImage} />}
        {twitterCard && <meta name='twitter:card' content={twitterCard} />}
        {twitterDescription && (
          <meta name='twitter:description' content={twitterDescription} />
        )}
        {twitterSite && <meta name='twitter:site' content={twitterSite} />}
        {twitterCreator && (
          <meta name='twitter:creator' content={twitterCreator} />
        )}
        {twitterImage && <meta name='twitter:image' content={twitterImage} />}
        {children}
      </Head>
      {url && <Script async data-cfasync='false' src={helpWidgetUrl} />}
    </>
  );
};

Meta.defaultProps = {
  charSet: 'utf-8',
  viewport: 'width=device-width,minimum-scale=1,initial-scale=1',
};

Meta.propTypes = {
  description: PropTypes.string,
  charSet: PropTypes.string,
  viewport: PropTypes.string,
  ogType: PropTypes.oneOf([
    'music.song',
    'music.album',
    'music.playlist',
    'music.radio_station',
    'video.movie',
    'video.episode',
    'video.tv_show',
    'video.other',
    'article',
    'book',
    'profile',
    'website',
  ]),
  ogTitle: PropTypes.string,
  ogDescription: PropTypes.string,
  ogSiteName: PropTypes.string,
  ogUrl: PropTypes.string,
  ogImage: PropTypes.string,
  twitterCard: PropTypes.oneOf([
    'summary',
    'summary_large_image',
    'player',
    'app',
    'og:type',
  ]),
  twitterDescription: PropTypes.string,
  twitterSite: PropTypes.string,
  twitterCreator: PropTypes.string,
  twitterImage: PropTypes.string,
  children: PropTypes.node,
};

export default Meta;
