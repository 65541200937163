import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
  Button,
  Flex,
  Spacer,
} from '@chakra-ui/react';
import i18n from 'lib/i18n';
import capitalize from 'lodash.capitalize';
import { tType, companyListType } from 'types';

const { withTranslation } = i18n;

const ChangeCompanyModal = ({
  companyList,
  onCancel,
  onConfirm,
  currentCompanyId,
  t,
}) => {
  const [value, setValue] = useState(currentCompanyId);

  function handleChange(event) {
    setValue(event.target.value);
  }

  return (
    <Modal isOpen={companyList} onClose={() => onCancel()}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{capitalize(t('change_company_view'))}</ModalHeader>
        <ModalCloseButton />
        {companyList && (
          <ModalBody paddingY={8} paddingBottom={6} paddingTop={0}>
            <Select value={value || ''} onChange={handleChange}>
              {companyList.map(company => {
                return (
                  <option key={company.id} value={company.id}>
                    {company.name}
                  </option>
                );
              })}
            </Select>
            <Flex marginTop={8}>
              <Spacer />
              <Button onClick={onCancel} variant='link'>
                {capitalize(t('cancel'))}
              </Button>
              <Button mr={3} onClick={() => onConfirm(value)}>
                {capitalize(t('change'))}
              </Button>
            </Flex>
          </ModalBody>
        )}
      </ModalContent>
    </Modal>
  );
};

ChangeCompanyModal.propTypes = {
  companyList: companyListType,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  currentCompanyId: PropTypes.number,
  t: tType,
};

export default withTranslation()(ChangeCompanyModal);
