import { arrayOf, shape, string, func, number, bool } from 'prop-types';

export const tType = func;

export const tankLevelsType = shape({
  items: arrayOf(
    shape({
      id: number,
      tankId: number,
      readingAt: string,
      inventoryRatio: string,
      inventory: string,
      ullage: string,
      change: string,
      capacity: string,
      battery: string,
      batteryVoltage: string,
      temp: string,
      current: bool,
      createdAt: string,
      updatedAt: string,
    }),
  ),
});

export const tankFillLevelsType = shape({
  items: arrayOf(
    shape({
      change: string,
      current: bool,
      efficiency: number,
      id: number,
      inventory: string,
      previousInventory: string,
      readingAt: string,
      readingid: number,
      tankid: number,
    }),
  ),
});

export const tankType = shape({
  capacity: string,
  capacityUnits: string,
  city: string,
  commissionedAt: string,
  content: string,
  country: string,
  createdAt: string,
  description: string,
  dimensionsUnits: string,
  endType: string,
  id: number,
  insideDiameter: string,
  lastModifiedBy: string,
  lastReadingAt: string,
  latitude: string,
  length: string,
  location: string,
  longitude: string,
  maxFill: string,
  monitorType: string,
  resourceStatus: string,
  sensor: string,
  starred: bool,
  state: string,
  tankType: string,
  temp: string,
  timezone: string,
  transmissionMode: string,
  updatedAt: string,
  currentLevel: shape({
    id: number,
    tankId: number,
    readingAt: string,
    inventoryRatio: string,
    inventory: string,
    ullage: string,
    change: string,
    capacity: string,
    battery: string,
    batteryVoltage: string,
    temp: string,
    current: bool,
    createdAt: string,
    updatedAt: string,
  }),
  zone: shape({
    id: number,
    name: string,
  }),
  levels: tankLevelsType,
});

export const alarmType = shape({
  id: number,
  name: string,
  threshold: string,
  active: bool,
  sendOnlyOnce: bool,
  receivers: arrayOf(string),
});

export const companyListType = arrayOf(
  shape({
    id: number,
    name: string,
    rep_first_name: string,
    rep_last_name: string,
    created_at: string,
    updated_at: string,
    default: bool,
  }),
);

export const zoneType = shape({
  id: number,
  companyId: number,
  name: string,
  billable: bool,
  default: bool,
  // eslint-disable-next-line consistent-return
  parentId: (props, propName, componentName) => {
    const { [propName]: value } = props;
    if (value !== null && typeof value !== 'number') {
      return new Error(
        `Invalid prop \`${propName}\` supplied to \`${componentName}\`. Validation failed.`,
      );
    }
  },
  createdAt: string,
  updatedAt: string,
});

zoneType.zones = arrayOf(shape(zoneType));
