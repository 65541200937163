import React, { useEffect, useState } from 'react';
import { bool } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchCompaniesList,
  fetchCurrentCompany,
  getCompanyList,
  getCurrentCompany,
  updateCurrentCompany,
} from 'stores/company';
import ChangeCompanyModal from 'components/ChangeCompanyModal';
import { useRouter } from 'next/router';
import { getCurrentCompanyId } from 'lib/auth';
import { Flex, Text, Icon } from '@chakra-ui/react';
import { IoChevronForward, IoLogOut } from 'react-icons/io5';
import uppercase from 'lodash.uppercase';
import i18n from 'lib/i18n';
import capitalize from 'lodash.capitalize';
import { tType } from 'types';

const { withTranslation } = i18n;

const ChangeCompanyLink = ({
  t,
  isCollapsed,
  isXactAdmin,
  isCompanyAdminPartner,
}) => {
  const dispatch = useDispatch();
  const router = useRouter();
  const companyList = useSelector(getCompanyList);
  const currentCompanyId = Number(getCurrentCompanyId());
  const { value: currentCompany } = useSelector(getCurrentCompany);
  const [displayModal, setDisplayModal] = useState(false);
  useEffect(() => {
    if (isXactAdmin || isCompanyAdminPartner) {
      dispatch(fetchCompaniesList());
    }
  }, [
    companyList.length,
    dispatch,
    isXactAdmin,
    currentCompany,
    isCompanyAdminPartner,
  ]);

  useEffect(() => {
    if (!currentCompany || currentCompanyId !== currentCompany?.id)
      dispatch(fetchCurrentCompany(currentCompanyId));
  }, [currentCompany, currentCompanyId, dispatch]);

  const handleCancel = () => setDisplayModal(false);

  const handleConfirm = value => {
    setDisplayModal(false);
    const companyId = Number(value);
    dispatch(updateCurrentCompany({ companyId }));
    router.push('/');
  };

  const handleClick = () => {
    if (isXactAdmin || isCompanyAdminPartner) {
      setDisplayModal(true);
    }
  };

  return (
    <div>
      <Flex
        data-testid='change-company-link'
        p='6'
        cursor={(isXactAdmin || isCompanyAdminPartner) && 'pointer'}
        minH='100px'
        maxHeight='165px'
        onClick={handleClick}
      >
        <Flex direction='column' alignSelf='flex-end'>
          <Icon
            as={IoLogOut}
            width='2em'
            height='2em'
            display={{ xs: isCollapsed ? 'flex' : 'none', lg: 'none' }}
            color='white'
            hidden={!isXactAdmin && !isCompanyAdminPartner}
          />
          <Text
            color='white'
            variant='textSm'
            display={{ xs: isCollapsed ? 'none' : 'flex', lg: 'flex' }}
          >
            {capitalize(t('company'))}
          </Text>
          <Text
            variant='headingMd'
            color='white'
            wordBreak='break-word'
            display={{ xs: isCollapsed ? 'none' : 'flex', lg: 'flex' }}
          >
            {currentCompany && uppercase(currentCompany.name)}
          </Text>
        </Flex>
        <Icon
          data-testid='change-company-arrow-down-icon'
          display={{ xs: isCollapsed ? 'none' : 'flex', lg: 'flex' }}
          hidden={!isXactAdmin && !isCompanyAdminPartner}
          as={IoChevronForward}
          boxSize='5'
          color='white'
          ml='auto'
          alignSelf='flex-end'
          mr='4'
        />
      </Flex>
      {displayModal && (
        <ChangeCompanyModal
          companyList={companyList}
          onCancel={handleCancel}
          onConfirm={handleConfirm}
          currentCompanyId={currentCompanyId}
        />
      )}
    </div>
  );
};

ChangeCompanyLink.propTypes = {
  t: tType,
  isCollapsed: bool,
  isXactAdmin: bool,
  isCompanyAdminPartner: bool,
};

export default withTranslation()(ChangeCompanyLink);
