export default {
  white: '#FFF',
  boneWhite: '#E5E5E5',
  error: '#FF0C3E',
  gray: {
    0.9: 'rgba(0, 0, 0, 0.9)',
    0.6: 'rgba(0, 0, 0, 0.6)',
    0.2: 'rgba(0, 0, 0, 0.2)',
    0.12: 'rgba(0, 0, 0, 0.12)',
  },
  darkBlue: '#4A5568',
};
