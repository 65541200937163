/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';
import {
  IoBusiness,
  IoDocumentText,
  IoLocationSharp,
  IoList,
  IoPeople,
} from 'react-icons/io5';
import DollarSign from 'components/icons/DollarSignIcon';
import { Box, Flex, Text } from '@chakra-ui/react';
import TankIcon from 'components/icons/TankIcon';
import i18n from 'lib/i18n';
import { tType } from 'types';

const { withTranslation } = i18n;

const SideBarLink = ({ linkName, id, isCollapsed, t }) => {
  const linkNameFormatted = linkName.replace(/([a-z])([A-Z])/g, '$1 $2');
  const LINK_CONFIG = {
    monitors: {
      linkIcon: <TankIcon color='white' />,
      linkPath: '/monitors',
    },
    zones: {
      linkIcon: <IoLocationSharp size='32px' />,
      linkPath: '/zones',
    },
    company: {
      linkIcon: <IoBusiness size='32px' />,
      linkPath: `/companies/${id}/edit`,
    },
    companies: {
      linkIcon: <IoBusiness size='32px' />,
      linkPath: '/companies',
    },
    price_plans: {
      linkIcon: <DollarSign w='2em' h='3em' />,
      linkPath: '/price_plans',
    },
    users: {
      linkIcon: <IoPeople size='32px' />,
      linkPath: '/users',
    },
    invoices: {
      linkIcon: <IoDocumentText size='32px' />,
      linkPath: '/invoices',
    },
    readings: {
      linkIcon: <IoList size='32px' />,
      linkPath: '/readings',
    },
  };

  const { linkIcon, linkPath } = useMemo(() => LINK_CONFIG[linkName] || {}, [
    LINK_CONFIG,
    linkName,
  ]);

  return (
    <Link href={linkPath}>
      <a>
        <Flex
          height='100px'
          cursor='pointer'
          key={linkName}
          p='6'
          alignItems='center'
          _hover={{ bg: 'alpha.300' }}
        >
          <Box color='white' width='24px' textAlign='center'>
            {linkIcon}
          </Box>
          <Text
            variant='headingMd'
            color='white'
            ml='8'
            display={{ xs: isCollapsed ? 'none' : 'flex', lg: 'flex' }}
            whiteSpace='nowrap'
            textTransform='uppercase'
          >
            {t(`sidebar.${linkNameFormatted}`)}
          </Text>
        </Flex>
      </a>
    </Link>
  );
};

SideBarLink.propTypes = {
  linkName: PropTypes.string.isRequired,
  id: PropTypes.number,
  isCollapsed: PropTypes.bool,
  t: tType,
};

export default withTranslation()(SideBarLink);
